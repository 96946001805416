



































































import {Component, Prop, Watch} from "vue-property-decorator";

interface ISoringPagedResultDto {
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

import {Vue} from "vue-property-decorator";

@Component({name: "PagedTableView"})
export default class PagedTableView extends Vue {
  @Prop()
  queryForm: any;

  @Prop({required: true})
  searchFunction!: any;

  @Prop({})
  pageSizes!: Array<number>;

  @Prop({required: false, default: 10})
  pageSize!: number;

  @Prop({required: false, default: 0})
  showTotalMoney!: number;
  @Prop({required: false, default: 0})
  totalMoney!: number;
  @Prop({required: false, default: "合计"})
  totalMoneyTitle!: number;
  @Prop({required: false, default: 0})
  hiddenResetSearch!: number;

  @Prop()
  resetForm!: any;

  @Prop({required: false})
  excludeResetKeys!: Array<string>;

  @Prop({required: false, default: false})
  hiddenQueryForm!: boolean;

  @Prop({required: false, default: ""})
  indexRouteName!: string;

  @Prop({required: false, default: 0})
  detailId: any;

  get pageSizeChoose() {
    if (this.pageSizes && this.pageSizes.length) {
      return this.pageSizes;
    } else {
      return [10, 20, 50, 100];
    }
  }

  showDetail = false;

  @Watch("detailId")
  onDetailIdChange(newValue: any) {
    if (newValue) {
      this.showDetail = true;
    } else {
      this.showDetail = false;
    }
  }

  // private fields
  tableItems: any = [];
  table: any = {
    listLoading: true,
    page: 1,
    totalCount: 0,
    pageSize: 0,
    sorting: "",
  };

  created() {
    this.table.pageSize = this.pageSize;
    this.fetchData();

    let $bus = (this as any).$bus;
    if ($bus) {
      $bus.$on("data-updated", () => this.fetchData());
      $bus.$on("data-created", () => this.fetchData());
      $bus.$on("data-deleted", () => this.fetchData());
      $bus.$on("api-action-completed", () => this.fetchData());

    }

  }

  handleSelectionChange(selection: any[]) {
    this.$emit("selectionList", selection);
  }

  refreshData() {
    console.log("pagedTableView:refreshData");
    this.table.page = 1;
    this.fetchData();
  }

  fetchData() {
    const pageParameter: ISoringPagedResultDto = {
      skipCount: (this.table.page - 1) * this.table.pageSize,
      maxResultCount: this.table.pageSize,
      sorting: this.table.sorting,
    };
    this.table.listLoading = true;

    const fetchResult = this.searchFunction(
      Object.assign({}, this.queryForm, pageParameter)
    );
    fetchResult.then((res: any) => {
      this.table.listLoading = false;
      this.tableItems = res.items!;
      this.table.totalCount = res.totalCount!;
      if (this.table.totalCount) {
        this.$emit("fetchDataList", this.tableItems);
      }
    });

    return fetchResult;
  }

  // events handles
  handleSearch() {
    this.table.page = 1;
    this.fetchData();
  }

  handleResetSearch() {
    if (this.resetForm !== null && typeof this.resetForm === "function") {
      this.resetForm();
    } else {
      if (this.queryForm) {
        for (let key in this.queryForm) {
          if (
            (this.excludeResetKeys &&
              this.excludeResetKeys.indexOf(key) === -1) ||
            !this.excludeResetKeys
          )
            this.queryForm[key] = undefined;
        }
      }
    }
    this.refreshData();
  }

  // 更新当前页
  async current_change(e: number) {
    this.table.page = e;
    await this.fetchData();
  }

  // Table排序
  async sort(e: any) {
    console.log("sort : ", e);
    if (e.prop && e.order) {
      this.table.sorting = `${e.prop} ${e.order}`;
    }
    this.fetchData();
  }

  // 修改一页显示的条目
  handleSizeChange(e: number) {
    this.table.pageSize = e;
    this.fetchData();
  }

  onCloseDetail() {
    this.showDetail = false;
    this.$emit("detail-close");
    (this as any).$router.push({
      name: this.indexRouteName,
    });
  }

  get detailTitle() {
    if ((this as any).$route.meta.title) {
      return (this as any).$route.meta.title;
    } else {
      return "详情";
    }
  }
}
